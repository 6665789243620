
import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  popoverController
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import {
  chevronForwardOutline,
  codeWorkingOutline,
  hardwareChipOutline,
  lockClosedOutline,
  openOutline,
  personCircleOutline,
  rocketOutline,
  shieldHalfOutline
} from 'ionicons/icons';
import { useAuthStore } from '@/store/auth';
import { useRouter } from "vue-router";
import { goToOms } from '@hotwax/dxp-components'
import { isMaargLogin } from '@/util';
import { translate } from '@/i18n';
import UserActionsPopover from '@/components/UserActionsPopover.vue'
import Image from "@/components/Image.vue";

export default defineComponent({
  name: 'Home',
  components: {
    Image,
    IonAvatar,
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage
  },
  ionViewDidEnter() {
    // clearing the redirect URL to break the login and redirection flow
    // if the user navigates to the home page while login
    this.authStore.setRedirectUrl('')
  },
  methods: {
    login() {
      // hydrate (pinia-plugin-persistedstate) will sync the app state with the
      // localStorage state for avoiding the case when two launchpad tabs are opened
      // and the user logs in through one and tries to login again from the next tab
      // $hydate will resync the state and hence, update the app UI
      this.authStore.$hydrate({ runHooks: false })
      // push to login only if user is not logged in (after state hydration)
      if (!this.authStore.isAuthenticated) {
        this.router.push('/login')
      }
    },
    async logout() {
      this.authStore.$hydrate({ runHooks: false })
      // hydrate and logout only if user is logged in (authenticated)
      if (this.authStore.isAuthenticated) {
        await this.authStore.logout()
      }
    },
    generateAppLink(app: any, appEnvironment = '') {
      const oms = isMaargLogin(app.handle, appEnvironment) ? this.authStore.getMaargOms : this.authStore.getOMS;
      window.location.href = this.scheme + app.handle + appEnvironment + this.domain + (this.authStore.isAuthenticated ? `/login?oms=${oms.startsWith('http') ? isMaargLogin(app.handle, appEnvironment) ? oms : oms.includes('/api') ? oms : `${oms}/api/` : oms}&token=${this.authStore.token.value}&expirationTime=${this.authStore.token.expiration}${isMaargLogin(app.handle, appEnvironment) ? '&omsRedirectionUrl=' + this.authStore.getOMS : ''}` : '')
    },
    async openUserActionsPopover(event: any) {
      const userActionsPopover = await popoverController.create({
        component: UserActionsPopover,
        event,
        showBackdrop: false,
      });

      userActionsPopover.present();
    }
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();

    const appInfo = [{
      handle: 'bopis',
      name: 'BOPIS',
      resource: require('../assets/images/BOPIS.svg'),
      type: 'Orders'
    }, {
      handle: 'fulfillment',
      name: 'Fulfillment',
      resource: require('../assets/images/Fulfillment.svg'),
      type: 'Orders'
    }, {
      handle: 'preorder',
      name: 'Pre-Orders',
      resource: require('../assets/images/PreOrder.svg'),
      type: 'Orders'
    },  {
      handle: 'atp',
      name: 'Available to Promise',
      resource: require('../assets/images/Atp.svg'),
      type: 'Workflow'
    }, {
      handle: 'job-manager',
      name: 'Job Manager',
      resource: require('../assets/images/Job.svg'),
      type: 'Workflow'
    }, {
      handle: 'receiving',
      name: 'Receiving',
      resource: require('../assets/images/Receiving.svg'),
      type: 'Inventory'
    }, {
      handle: 'inventorycount',
      name: 'Cycle Count',
      resource: require('../assets/images/CycleCount.svg'),
      type: 'Inventory'
    }, {
      handle: 'picking',
      name: 'Picking',
      resource: require('../assets/images/Picking.svg'),
      type: 'Inventory'
    }, {
      handle: 'import',
      name: 'Import',
      resource: require('../assets/images/Import.svg'),
      type: 'Administration'
    }, {
      handle: 'users',
      name: 'Users',
      resource: require('../assets/images/UserManagement.svg'),
      type: 'Administration'
    }, {
      handle: 'facilities',
      name: 'Facilities',
      resource: require('../assets/images/Facilities.svg'),
      type: 'Administration'
    }, {
      handle: 'order-routing',
      name: 'Order Routing',
      resource: require('../assets/images/OrderRouting.svg'),
      type: 'Workflow'
    }, {
      handle: 'company',
      name: 'Company',
      resource: require('../assets/images/Company.svg'),
      type: 'Administration'
    }]

    const appCategory = appInfo.reduce((obj: any, app: any) => {
      if (obj[app.type]) {
        obj[app.type].push(app)
      } else {
        obj[app.type] = [app]
      }

      return obj
    }, {})

    const scheme = ref('https://')
    const domain = ref('.hotwax.io')
    const uatHandle = ref('-uat')
    const devHandle = ref('-dev')

    return {
      authStore,
      appCategory,
      chevronForwardOutline,
      codeWorkingOutline,
      devHandle,
      domain,
      goToOms,
      isMaargLogin,
      lockClosedOutline,
      hardwareChipOutline,
      openOutline,
      personCircleOutline,
      rocketOutline,
      router,
      scheme,
      shieldHalfOutline,
      translate,
      uatHandle
    }
  }
});
