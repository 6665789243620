import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.authStore.current?.partyName ? _ctx.authStore.current.partyName : _ctx.authStore.current.userLoginId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToUserDetails()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("View profile")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_icon, { icon: _ctx.personCircleOutline }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            lines: "none",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { color: "danger" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Logout")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_icon, {
                icon: _ctx.exitOutline,
                color: "danger"
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}