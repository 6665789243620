import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b417a9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "ion-padding" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ion-text-center ion-margin-bottom" }
const _hoisted_6 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.hideBackground && !_ctx.isConfirmingForActiveSession)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("form", {
                  class: "login-container",
                  onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => (_ctx.handleSubmit()), ["enter"])),
                  onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createVNode(_component_Logo),
                  (_ctx.showOmsInput)
                    ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                        _createVNode(_component_ion_item, { lines: "full" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              label: _ctx.$t('OMS'),
                              "label-placement": "fixed",
                              name: "instanceUrl",
                              modelValue: _ctx.instanceUrl,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.instanceUrl) = $event)),
                              id: "instanceUrl",
                              type: "text",
                              required: ""
                            }, null, 8, ["label", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_ion_button, {
                            color: "primary",
                            expand: "block",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isCheckingOms ? '' : _ctx.setOms()), ["prevent"])),
                            onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"]))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("Next")) + " ", 1),
                              (_ctx.isCheckingOms)
                                ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                    key: 0,
                                    name: "crescent",
                                    "data-spinner-size": "medium",
                                    slot: "end"
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 1,
                                    slot: "end",
                                    icon: _ctx.arrowForwardOutline
                                  }, null, 8, ["icon"]))
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("section", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_ion_chip, {
                            outline: true,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleOmsInput()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.authStore.getOMS), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_ion_item, { lines: "full" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              label: _ctx.$t('Username'),
                              "label-placement": "fixed",
                              name: "username",
                              modelValue: _ctx.username,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event)),
                              id: "username",
                              type: "text",
                              required: ""
                            }, null, 8, ["label", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_input, {
                              label: _ctx.$t('Password'),
                              "label-placement": "fixed",
                              name: "password",
                              modelValue: _ctx.password,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.password) = $event)),
                              id: "password",
                              type: "password",
                              required: ""
                            }, null, 8, ["label", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_ion_button, {
                            color: "primary",
                            expand: "block",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isLoggingIn ? '' : _ctx.login()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("Login")) + " ", 1),
                              (_ctx.isLoggingIn)
                                ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                    key: 0,
                                    slot: "end",
                                    name: "crescent",
                                    "data-spinner-size": "medium"
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 1,
                                    slot: "end",
                                    icon: _ctx.arrowForwardOutline
                                  }, null, 8, ["icon"]))
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                ], 32)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_fab, {
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.router.push('/'))),
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, { color: "medium" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.gridOutline }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}