
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { translate } from "@/i18n";
import { exitOutline, personCircleOutline } from 'ionicons/icons';
import { useAuthStore } from '@/store/auth';

export default defineComponent({
  name: "UserActionsPopover",
  components: {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
  },
  methods: {
    redirectToUserDetails() {
      window.location.href = `${process.env.VUE_APP_USERS_LOGIN_URL}?oms=${this.authStore.oms}&token=${this.authStore.token.value}&expirationTime=${this.authStore.token.expiration}&partyId=${this.authStore.current.partyId}`
      popoverController.dismiss()
    },
    async logout() {
      await this.authStore.logout()
      popoverController.dismiss()
    }
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore,
      exitOutline,
      personCircleOutline,
      translate
    }
  }
});
